(function($) {

	var init = function() {

		serviceIcons();
	};

	serviceIcons = function() {

		var $iconLinks = $('.services-icons a');

		$iconLinks.each(function() {
			var $icon = $(this).find('img');
			var src = $icon.attr('data-icon-src');
			var over = $icon.attr('data-over');
			UTIL.preloadImage(src+over);
		});

		$iconLinks.on('mouseover',function() {
			if($(this).hasClass('active')) {
				return true;
			}
			var $icon = $(this).find('img');
			var src = $icon.attr('data-icon-src');
			var over = $icon.attr('data-over');
			$icon.attr('src',src+over);
		});

		$iconLinks.on('mouseout',function() {
			if($(this).hasClass('active')) {
				return true;
			}
			var $icon = $(this).find('img');
			var normal = $icon.attr('data-normal') || '';
			var src = $icon.attr('data-icon-src');
			$icon.attr('src',src+normal);
		});

		var $ActiveeIconLinks = $('.services-icons a.active');

		$ActiveeIconLinks.each(function(index) {
			var $icon = $(this).find('img');
			var src = $icon.attr('data-icon-src');
			var over = $icon.attr('data-over');
			$icon.attr('src',src+over);
		});

	};

	$(document).ready(function() {
		init();
	});

})(jQuery);