

$(document).ready(function() {
	$(".mail-icon-list a[href^='#']").click(function() {
		if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
			var target = $(this.hash);
			target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
			if (target.length) {
				$('html,body').animate({
					scrollTop: target.offset().top - $('#affixbottom').height()
				}, 250);
				return false;
			}
		}
	});
});