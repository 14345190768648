(function($) {


	var selector = '.callback-form';
	var fields = ['name','number','date'];
	var s = $(selector);
	var trigger = 'phonecallback';

	var reInit = function() {
		s = $(selector);
	};

	var init = function() {
		$(document).on('submit',selector,function(event){

			reInit();
			event.preventDefault();
			removeErrorHint();

			if(checkFields()) {
				//fields[root.editor][Telefon]
				var data = {};

				for(var i=0; i < fields.length;i++) {
					data[fields[i]] = s.find("input[name='"+fields[i]+"']").val();
				}
				data['salutation'] = s.find("select[name='salutation']").val();;
				data['triggers'] = trigger;


				//progress
				s.find("button[type='submit']").attr('disabled','disabled').addClass('disabled');
				s.find(".progressbutton").css('display','inline-block');

				$.ajax({
					method: 'post',
					url: '/ajax/dotrigger/'+trigger,
					data: data
				})
				.done(function( data ) {
					s.find('.alert-success').css('display','block');
					for(var i=0; i < fields.length;i++) {
						var f = s.find("input[name='"+fields[i]+"']");
						f.attr('disabled','disabled').addClass('disabled');
					}
					s.find("button[type='submit']").attr('disabled','disabled').addClass('disabled');
					s.find("select[name='salutation']").attr('disabled','disabled').addClass('disabled');
					s.find(".progressbutton").css('display','none');
				});
			} else {
				showErrorHint();
				s.find(".progressbutton").css('display','none');
				s.find("button[type='submit']").removeAttr('disabled').removelass('disabled');
			}

			return false;
		});
	};

	var showErrorHint = function() {
		s.find(".alert-danger").css('display','block');
	};

	var removeErrorHint = function() {
		s.find(".alert-danger").css('display','none');
	};

	var checkFields = function() {
		var check = true;

		for(var i=0; i < fields.length;i++) {
			var f = s.find("input[name='"+fields[i]+"']");
			if((!f) || (!f.length)) {
				check = false;
			} else {
				var v = f.val();
				if((!v) || (!v.length)) {
					check = false;
				}
			}
		}

		return check;
	};

	$(document).ready(function() {
		init();
	});

})(jQuery);