

// setHidden(2,'head_fields_root_browser_wrapper_browser_page_offset');
// IF.browserSubmit('p','head_fields_root_browser_wrapper_browser_hidden_offset');return false;

/**
 * Old stuff for vbrowser (newsletter archive)
 */

if(typeof IF === 'object') {
	IF.browserSubmit = function(v,i,eidb) {
		document.getElementById(i).value = v;
		document.forms['contentform'].onsubmit(); // workaround browser bugs.
		document.forms['contentform'].submit();
	}

	IF.setHiddenAndSubmit = function(hidden_value,hidden_id,formname) {
		document.getElementById(hidden_id).value = hidden_value;
		document.forms[formname].onsubmit();
		document.forms[formname].submit();
	}
}

if(typeof setHidden !== 'function') {
	setHidden = function(v,i) {
		document.getElementById(i).value = v;
	};
}

/**
 * Generate Dynamic IDs
 * @constructor
 */
function Generator() {};
Generator.prototype.rand =  Math.floor(Math.random() * 26) + Date.now();
Generator.prototype.getId = function() {
	return 'gen-' + this.rand++;
};
var idGen = new Generator();

function Local() {

	this.initialized=false;
	this.topSwiperImagesLoaded = false;

	this.init = function()
	{
		if(this.initialized) return false;
		this.initialized=true;

		$('body').addClass('js');

		this.initAffix();
		// this.initSwiper();
		this.initLazyLoadSections();

		this.checkTopSwiperBGIMagesLoaded();
		this.startHome();
		this.initViewPortAnimations();
		this.initGmap();
	};

	this.initGmap = function() {

		$('#googlemap').viewportChecker({
			classToAdd: 'js--visible', // Class to add to the elements when they are visible,
			classToAddForFullView: 'full-visible', // Class to add when an item is completely visible in the viewport
			classToRemove: 'js--hidden', // Class to remove before adding 'classToAdd' to the elements
			offset: 100,
			callbackFunction: function(elem, action){
				initializeGmap();
			}
		});
	};

	this.initViewPortAnimations = function () {

		$('.section--animate').viewportChecker({
			classToAdd: 'js--visible animated fadeInLeftBig', // Class to add to the elements when they are visible,
			classToAddForFullView: 'full-visible', // Class to add when an item is completely visible in the viewport
			classToRemove: 'js--hidden', // Class to remove before adding 'classToAdd' to the elements
			// removeClassAfterAnimation: false, // Remove added classes after animation has finished
			offset: '10%', // The offset of the elements (let them appear earlier or later). This can also be percentage based by adding a '%' at the end
			// invertBottomOffset: true, // Add the offset as a negative number to the element's bottom
			repeat: false // Add the possibility to remove the class if the elements are not visible
			// callbackFunction: function(elem, action){
			// 	if(typeof console === 'object') { console.log('VIEWPORT callbackFunction',elem, action); }
			// }, // Callback to do after a class was added to an element. Action will return "add" or "remove", depending if the class was added or removed
			// scrollHorizontal: false // Set to true if your website scrolls horizontal instead of vertical.
		});

		$('.rubrikbild_hg').addClass('js--hidden');
		$('.rubrikbild_hg').viewportChecker({
			classToAdd: 'js--visible animated zoomIn', // Class to add to the elements when they are visible,
			classToAddForFullView: 'full-visible', // Class to add when an item is completely visible in the viewport
			classToRemove: 'js--hidden', // Class to remove before adding 'classToAdd' to the elements
			// removeClassAfterAnimation: false, // Remove added classes after animation has finished
			offset: '10%', // The offset of the elements (let them appear earlier or later). This can also be percentage based by adding a '%' at the end
			// invertBottomOffset: true, // Add the offset as a negative number to the element's bottom
			repeat: false // Add the possibility to remove the class if the elements are not visible
			// callbackFunction: function(elem, action){
			// 	if(typeof console === 'object') { console.log('VIEWPORT callbackFunction',elem, action); }
			// }, // Callback to do after a class was added to an element. Action will return "add" or "remove", depending if the class was added or removed
			// scrollHorizontal: false // Set to true if your website scrolls horizontal instead of vertical.
		});
	};

	this.startHome = function() {

		var me = this;

		if(!$('body').hasClass('start-animation')) {
			$('.topaddress').css('visibility','visible');
			$('.logo-col').css('visibility','visible');
			return;
		}

		if(this.topSwiperImagesLoaded) {

			if(($(window).scrollTop())>35) {
				$('.topaddress').css('visibility', 'visible');
				$('.topaddress').addClass('animated fadeIn');
				$('.logo-col').css('visibility', 'visible');
			}

			setTimeout(function() {

				if(($(window).scrollTop())>35) {

					$('.topaddress').css('visibility', 'visible');
					$('.topaddress').addClass('animated fadeIn');
					$('.logo-col').css('visibility', 'visible');

					setTimeout(function() {
						me.loadTXTAnimation();
						me.backgroundImageGrowAndShrink();
					},2000);
				} else {
					var animationEnd = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';
					$('.topaddress').css('visibility','visible');
					$('.topaddress').addClass('animated slideInDown').one(animationEnd, function() {

						$('.logo-col').css('visibility','visible');
						$('.logo-col').addClass('animated fadeIn');

						setTimeout(function() {
							me.loadTXTAnimation();
							me.backgroundImageGrowAndShrink();
						},1000);
					});
				}


			},500);

		} else {
			setTimeout(function() {
				me.startHome();
			},1000);
		}

	};

	this.backgroundImageGrowAndShrink = function() {

		var me = this;
		var BGS = $('.top-slider .slide0 .bgimage');
		var time = 50000;

		if(this.topSwiperImagesLoaded) {

			if(!BGS.hasClass('background-image--transition')) {
				BGS.addClass('background-image--transition');
			}

			if(BGS.hasClass('background-image--grow')) {
				BGS.removeClass('background-image--grow');
				BGS.addClass('background-image--shrink');
				setTimeout(function() {
					me.backgroundImageGrowAndShrink();
				},time);
			} else {
				BGS.removeClass('background-image--shrink');
				BGS.addClass('background-image--grow');
				setTimeout(function() {
					me.backgroundImageGrowAndShrink();
				},time);
			}

		} else {
			setTimeout(function() {
				me.backgroundImageGrowAndShrink();
			},1000);
		}
	};

	this.loadTXTAnimation = function() {

		var me = this;

		if(this.topSwiperImagesLoaded) {
			$('.content  .swiper-container .global-title .tlt ul').css('visibility','visible');
			var listItems = $('.content  .swiper-container .global-title .tlt ul li');
			$('.tlt').textillate({
				loop: (listItems.length && listItems.length > 1) ? true : false
				// type: 'word',
				// sync: true
			});
		} else {
			setTimeout(function() {
				me.loadTXTAnimation();
			},1000);
		}
	}


	this.checkTopSwiperBGIMagesLoaded = function() {

		var me = this;

		if(this.topSwiperImagesLoaded) {
			return this.topSwiperImagesLoaded;
		}

		var laszyBGS = $('.top-slider .lazyBg');

		if((!laszyBGS) || (!laszyBGS.get(0))) {
			this.topSwiperImagesLoaded = true;
		} else {
			setTimeout(function() {
				me.checkTopSwiperBGIMagesLoaded();
			},1000);
		}

		return this.topSwiperImagesLoaded;
	};

	this.initAffix = function() {

		var me = this;

		$('#affixtop').affix({
			offset: {
				//top: 26
				top: function () {
					return 0;
				}
			}
		});

		$('#affixbottom').affix({
			offset: {
				//top: 26
				top: function () {
					var firstSwiperHeight = 0;
					var firstSwiperEl = $('.content section').first();
					if(firstSwiperEl.hasClass('bgimageswiper')) {
						firstSwiperHeight = firstSwiperEl.outerHeight(true);
					}
					var affixtoMargin = 35;
					if(($(window).scrollTop())>35) {
						affixtoMargin = 0;
					}
					return (this.top = $('header#mainnav').outerHeight(true) + firstSwiperHeight - affixtoMargin) // .affix + .header-content margin-top = 484 / 513//
				}
			}
		});
	};

	/**
	 * Init all swipers
	 */
	this.initSwiper = function() {

		var swipers = $('.swiper-container');
		var ww = window.innerWidth;

		swipers.each(function( index ) {
			if(!$(this).attr('id')) {
				$(this).attr('id',idGen.getId());
			}

			var id = $(this).attr('id');
			var slides = $(this).find('.swiper-slide');

			$('#'+id).swiper({
				pagination: (1===2 && slides.length > 1 && ww > 768) ? '#'+id+' .swiper-pagination' : null,
				autoplay: 6000,
				paginationClickable: true
			});
		});
	};

	/**
	 * why don´t create a new stylesheet?
	 * @returns {*}
	 */
	this.getLastStylesheet = function () {
		if (this.styleSheet) return this.styleSheet;

		// create a stylesheet
		var styleTag = document.createElement("style");
		var head = document.getElementsByTagName("head")[0];
		head.appendChild(styleTag);

		this.styleSheet = styleTag.sheet ? styleTag.sheet : styleTag.styleSheet;
		return this.styleSheet;
	}

	/**
	 *
	 * @param selector
	 * @param style
	 * @param idx deprecated
	 */
	this.insertRule = function(selector,style,idx) {
		try {
			if(!this.styleSheet) this.styleSheet = this.getLastStylesheet();
			if (this.styleSheet.insertRule) {   // all browsers, except IE before version 9
				this.styleSheet.insertRule (selector+" {"+style+"}", this.styleSheet.cssRules.length);
			}
			else if (this.styleSheet.addRule) {  // Internet Explorer before version 9
				//if(typeof console == 'object') console.log('x this.styleSheet.rules.length '+ this.styleSheet.rules.length+': %o',this.styleSheet.rules.length);
				this.styleSheet.addRule(selector, style, this.styleSheet.rules.length);
			}
		} catch(e) {
			if(typeof console == 'object') console.log('Unsupported insertRule / addRule',e);
		}
	};

	this.initLazyLoadSections = function() {

		var sections = $('section');
		this.sections = [];
		for(var i = 0;i < sections.length;i++) {
			this.sections[i] = {
				section: $(sections[i]),
				id: sections[i].id,
				loaded: false
			};
		}

		$(window).scroll(function(e) {
			LC.lazyLoadSections();
		});
		this.lazyLoadSections();
	}

	this.lazyLoadSections = function() {
		var wheight = $(window).height();
		var scrollTop = $(window).scrollTop();
		for(var i = 0;i < LC.sections.length;i++) {
			var offset = LC.sections[i].section.offset();

			if((!(LC.sections[i].loaded) && (wheight+scrollTop) >= offset.top)) {
				LC.sections[i].loaded = true;
				LC.lazyLoadContent(LC.sections[i].section);
			}
		}
	}

	/**
	 * Lazy load content
	 *
	 * @param p
	 */
	this.lazyLoadContent = function (p) {

		var src,xImg,i,dataLoad;

		// lazy load bg images
		var loadBgImg = function(el) {
			jel = $(el);
			var src = jel.attr('data-img');
			if (src && src.length > 0) {
				var i = new Image();
				i.src = src;
				i.onload = function () {
					jel = $(el);
					jel.removeClass('lazyBg');
					jel.css('backgroundImage','url('+src+')');
					jel.removeAttr('data-img');
				};
			}
		};

		var els = p.find('.lazyBg');
		if(p.hasClass('lazyBg')) {
			els.push(p);
		}
		var els_length = els.length;
		for (imidx = 0; imidx < els_length; imidx++) {
			var el = els[imidx];
			loadBgImg(el);
		}

		var loadImg = function(img,p) {
			var jimg = $(el);
			var src = jimg.attr('data-img');
			if (src && src.length > 0) {
				i = new Image();
				i.src = src;
				i.onload = function () {
					jimg.attr('src', src);
					jimg.removeClass('lazyImg');
					jimg.removeAttr('data-img');
				};
			}
		};

		// lazy load images
		var imgs = p.find('.lazyImg');
		var img_length = imgs.length;
		for (var imidx = 0; imidx < img_length; imidx++) {
			var img = imgs[imidx];
			loadImg(img,p);
		}
	};
}

LC = new Local();

$( document ).ready(function() {
	LC.init();
});



/**
 *
 * UTILS Functions
 *
 * needs: jquery
 *
 * @constructor
 */

function Utils() {


	this.support = {};
	this.onGesture = false;

	this.init = function() {
		this.testTransitionSupport();
		this.user._init();
		this.orientation = this.getOrientation();
		this.setOrientationEvent();
		//if(typeof console === 'object') { console.log('UTIL Support',this.support); }
	};

	// UTIL.preloadImage();
	this.preloadImage = function(src) {

		var i = new Image();
		i.src = src;

	};

	// TEST FUNCTIONS

	this.escapeSelector = function(name) {
		name = name.replace(/\./g,'\\.');
		name = name.replace(/=/g,'\\=');
		return name.replace(/\//g,'\\/');
	};

	this.isSupportedEvent = function (obj, event) {
		if (event.substring(0, 2) != 'on') event = 'on' + event;
		return event in obj;
	};

	this.isTouchSupported = function() {
		return (typeof(window.ontouchstart) != 'undefined');
	};

	this.test=function() {
		if(typeof console === 'object') { console.log('UTIL I WANT GLOBALS',true); }
	};

	this.testProps = function ( props, prefixed ) {
		var _style = document.createElement('div').style;
		for ( var i in props ) {
			if ( _style[ props[i] ] !== undefined ) {
				return prefixed == 'pfx' ? props[i] : true;
			}
		}
		return false;
	};

	this.transitionEnd = function () {
		var el = document.createElement('bootstrap');

		var transEndEventNames = {
			'WebkitTransition'   : 'webkitTransitionEnd'
			,'MozTransition'    : 'transitionend'
			,'OTransition'      : 'oTransitionEnd otransitionend'
			,'transition'       : 'transitionend'
			,'msTransition'      : 'MSTransitionEnd'
		}

		for (var name in transEndEventNames) {
			if (el.style[name] !== undefined) {
				return { end: transEndEventNames[name] }
			}
		}

		//var transEndEventNames = {
		//	'WebkitTransition' : 'webkitTransitionEnd',// Saf 6, Android Browser
		//	'MozTransition'    : 'transitionend',      // only for FF < 15
		//	'transition'       : 'transitionend'       // IE10, Opera, Chrome, FF 15+, Saf 7+
		//},
		//transEndEventName = transEndEventNames[ Modernizr.prefixed('transition') ];
	};

	// TRANSITIONS

	this.emulateTransitionEnd = function ($el,duration) {
		var called = false;

		$el.one(this.support.transition.end, function () { called = true });
		var callback = function () { if (!called) $($el).trigger(this.support.transition.end) };
		setTimeout(callback, duration);
		return this;

	};

	this.csstransforms = function () {
		if(Modernizr.csstransforms) {
			return true;
		} else {
			var prefixes = ['transformProperty', 'WebkitTransform', 'MozTransform', 'msTransform'];
			return !!this.testProps( prefixes );
		}

	};

	this.csstransforms3d = function () {
		if (Modernizr.csstransforms3d) {
			return true;
		} else {
			return ('WebKitCSSMatrix' in window && 'm11' in new WebKitCSSMatrix());
		}
	};

	this.testTransitionSupport = function() {
		this.support.transition = this.transitionEnd();
		this.support.csstransforms = this.csstransforms();
		this.support.csstransforms3d = this.csstransforms3d();

		this.support.transform = Modernizr.prefixed('transform');
		this.support.transitionend = Modernizr.prefixed('transition');
		this.support.transitionDuration = Modernizr.prefixed('transitionDuration');
		this.support.transitionTimingFunction = Modernizr.prefixed('transitionTimingFunction');
	};

	this.testWebcamSupport = function() {

		return this.support.webcam = (navigator.getUserMedia ||
		navigator.webkitGetUserMedia ||
		navigator.mozGetUserMedia ||
		navigator.msGetUserMedia ||
		false);

	};

	this.hyphenateCSS = function(str) {
		return str.replace(/([A-Z])/g, function(str,m1){ return '-' + m1.toLowerCase(); }).replace(/^ms-/,'-ms-');
	};

	this.transform = function ($el,transform) {

		var me = this,
			scale = transform.scale,
			origin,
			transformX = (typeof transform.x == 'number') ? transform.x+'px' : transform.x,
			transformY = (typeof transform.y == 'number') ? transform.y+'px' : transform.y;

		//if(typeof console === 'object') { console.log('UTIL.transform this.support',this.support); }

		// define scale factor
		if(typeof scale == 'undefined') {
			scale = 1;
		}

		// set transform origin
		if(transform.origin) {
			origin = (me.support.csstransforms3d) ? transform.origin.x+'px '+transform.origin.y+'px 0px' : transform.origin.x+'px '+transform.origin.y+'px';
			$el.css({
				transformOrigin: origin
			});
		}

		// CSS3 Transforms3D Animation
		if(this.support.csstransforms3d) {
			//if(typeof console === 'object') { console.log('typeof transform.scale',typeof scale); }
			if(typeof scale == 'number') {
				scale = scale+','+scale+',1';
			}
			$el.css(me.support.transform, "translate3d("+transformX+","+transformY+",0) scale3d("+scale+")");
		}

		// CSS3 Transform Animation
		else if($.support.csstransforms) {
			if(typeof scale == 'number') {
				scale = scale+','+scale;
			}
			$el.css(me.support.transform, "translate("+transformX+","+transformY+") scale("+scale+")");
		}

		// CSS3 Transition
		else {

			$el.css({
				translate: [transformX,transformY],
				scale: transform.scale
			});
			//
			//$el.css({
			//	left: transformX,
			//	top: transformY
			//});
		}
	};

	this.stopTransit = function($el,transform,settings) {
		var animate = 'animate' || settings.animate;
		$el.removeClass(animate);
		// stay on the actuell position? remove all transition styles?
	};

	this.transit = function($el,transform,settings) {

		var me = this,
			origin;

		if((!$el) || (!$el.get(0))) {
			if(typeof console === 'object') { console.log('WARNING transit failed see settings for details ',$el,transform,settings); }
			return false;
		}
		//if(typeof console === 'object') { console.log('TRANSIT',$el,transform,settings); }

		// set transform origin
		if(transform.origin) {
			origin = (me.support.csstransforms3d) ? transform.origin.x+'px '+transform.origin.y+'px 0px' : transform.origin.x+'px '+transform.origin.y+'px';
			$el.css({
				transformOrigin: origin
			});
		}
		else if(settings.transformOrigin) {

			var transformOrigin = {
				x: transform.x,
				y: transform.y
			};

			$el.css({
				transformOrigin: transformOrigin.x+'px '+transformOrigin.y+'px'
			});
		}

		var animate = settings.animate || 'animate';
		var duration = settings.duration || 300;
		var easing =  settings.easing || 'cubic-bezier(0,0.9,0.3,1)';
		var callback = settings.callback || function() {};

		// set timing
		var setTiming = function() {
			if(duration > 0) {
				$el.get(0).style[me.support.transitionDuration] = duration+'ms';
			}
			if(easing) {
				$el.get(0).style[me.support.transitionTimingFunction] = easing;
			}
		};

		// remove the timings
		var removeTiming = function() {
			$el.get(0).style[me.support.transitionDuration] = null;
			$el.get(0).style[me.support.transitionTimingFunction] = null;
			origin = (me.support.csstransforms3d) ? '0px 0px 0px' : '0px 0px';
			$el.css({
				transformOrigin: origin
			});
		};

		// oncomlete call callback and remove animation class
		// remove timing?
		var completeFN = function() {

			var cb = function(e) {
				removeTiming();
				$el.removeClass(animate);
				callback(e,$el,transform,settings);
			};

			me.call_transitionEnd($el,duration,cb);


			//if( me.support.transition ) {
			//
			//	// this is a trick, cause if the duration is somehow 0
			//	// or the transition endpoint is exactly like the the startpoint
			//	// the transitionend function is not called
			//	var called = false;
			//
			//	$el.one(me.support.transition.end, function (e) { called = true; cb(e); });
			//	var transitionCallback = function () {
			//		if (!called) {
			//			$($el).trigger(me.support.transition.end);
			//		}
			//	};
			//	setTimeout(transitionCallback, duration);
			//
			//} else {
			//	setTimeout(cb,duration);
			//}
		};


		if(this.support.csstransforms3d) {
			$el.addClass(animate);
			setTiming();
			completeFN();
			$el.css(me.support.transform, "translate3d("+transform.x+"px,"+transform.y+"px,0) scale3d(1,1,1)");

		}

		// CSS3 Transform Animation
		else if($.support.csstransforms) {
			$el.addClass(animate);
			setTiming();
			completeFN();
			$el.css(me.support.transform, "translate("+transform.x+"px,"+transform.y+"px)");

			//$el.css({
			//	translate: [transform.x,transform.y],
			//	scale: transform.scale
			//});
		}

		// CSS3 Transition
		else {
			// fallback?
			$el.transition({
				translate: [transform.x,transform.y],
				duration: duration,
				easing: easing,
				complete: callback
			});
		}

	};


	this.call_transitionEnd = function($el,duration,cb) {

		var me = this;

		if((!$el) || (!$el.get(0))) return false;

		if( me.support.transition ) {

			// this is a trick, cause if the duration is somehow 0
			// or the transition endpoint is exactly like the the startpoint
			// the transitionend function is not called
			var called = false;

			$el.one(me.support.transition.end, function (e) { called = true; cb(e); });
			var transitionCallback = function () {
				if (!called) {
					$($el).trigger(me.support.transition.end);
				}
			};
			setTimeout(transitionCallback, duration);

		} else {
			setTimeout(cb,duration);
		}

	};


	this.stopMomentum = function(type) {
		if(!this.momentumSettings[type]) {
			this.momentumSettings[type] = {};
		}

		this.momentumSettings[type]['currentMomentumStep'] = 0;
	};

	this.momentumSettings = {
		mosaic:{
			currentMomentumStep: 100,
			minDistance: 40,
			speedImprovement: 1
		},
		wall:{
			currentMomentumStep: 100,
			minDistance: 40,
			speedImprovement: 2
		},
		wallRectangle:{
			currentMomentumStep: 100,
			minDistance: 40,
			speedImprovement: 2
		}
	};
	/**
	 * Momentum: Smooth roll out of an item after dragging or touching
	 * Needs and event, an element and and transform settings
	 *
	 * Todo: Momentum Finetuning!
	 *

	 UTIL.momentum(e,$el,'my-options-key',transform,{
		        runningMouseEvents: runningMouseEvents, // storage of last mouse event(s)
		        endCallback: endMouseUp, // callback
		        checkBoundaries: function() { // boundaries check (on transform - must be a reference)
		            checkBoundaries($cont);
	            }
	        });

	 *
	 * @param e
	 * @param $el
	 * @param type
	 * @param transform
	 * @param options
	 * @returns {*}
	 *
	 * @link http://www.paulirish.com/2011/requestanimationframe-for-smart-animating/
	 */
	this.momentum = function(e,$el,type,transform,options) {

		var me = this;

		if(!this.momentumSettings[type]) {
			this.momentumSettings[type] = {};
		}

		if(!this.momentumSettings[type]['currentMomentumStep']) {
			this.momentumSettings[type]['currentMomentumStep'] = 100;
		}

		//if(typeof console === 'object') { console.log('util.momentum type,transform.x,transform.y,options',type,transform.x,transform.y,options); }

		var lastE = options.runningMouseEvents.shift();
		if(!lastE) {
			return options.endCallback(e);
		}

		var x1 = (lastE.pointerType == 'touch') ? lastE.deltaX : lastE.pageX;
		var y1 = (lastE.pointerType == 'touch') ? lastE.deltaY : lastE.pageY;
		var t1 = lastE.timeStamp;
		var x2 = (e.pointerType == 'touch') ? e.deltaX : e.pageX;
		var y2 = (e.pointerType == 'touch') ? e.deltaY : e.pageY;
		var t2 = e.timeStamp;

		// Deltas
		var dX = x2 - x1,
			dY = y2 - y1,
			dMs = Math.max(t2 - t1, 1),
			pos = {
				x: transform.x,
				y: transform.y
			},
			lastPos = {
				x: transform.x,
				y: transform.y
			},
			fixStepFloats = 2,
			speedImprovement = me.momentumSettings[type].speedImprovement || 1;//(e.pointerType == 'touch') ? 1 : 1;

		// Speeds
		var speedX = Math.max(Math.min(dX/dMs, 1), -1),
			speedY = Math.max(Math.min(dY/dMs, 1), -1);

		// Distance moved (Euclidean distance)
		var distance = Math.sqrt(Math.pow(x1-x2, 2) + Math.pow(y1-y2, 2));

		//if(typeof console == 'object') console.log('distance,minDistance',distance,me.momentumSettings[type].minDistance);

		if (distance > me.momentumSettings[type].minDistance) {

			// Momentum
			var lastStepTime = new Date();
			var duration = Math.max(Math.abs(speedX), Math.abs(speedY)) * 2000;
			var stopTime = lastStepTime.getTime()+duration;

			//if(typeof console === 'object') { console.log('duration,currentMomentunmStep',duration); }

			// requestAnim shim layer by Paul Irish
			window.requestAnimFrame = (function(){
				return  window.requestAnimationFrame       ||
					window.webkitRequestAnimationFrame ||
					window.mozRequestAnimationFrame    ||
					window.oRequestAnimationFrame      ||
					window.msRequestAnimationFrame     ||
					function(/* function */ callback, /* DOMElement */ element){
						window.setTimeout(callback, 1000 / 60);
					};
			})();


			function animate() {
				//if(typeof console === 'object') { console.log('animate stopTime,now.getTime()',me.momentumSettings[type]['currentMomentumStep'],stopTime,new Date().getTime()); }
				if(me.momentumSettings[type]['currentMomentumStep'] === 0) {
					//if(typeof console === 'object') { console.log('animate END MOUSUP',me.momentumSettings[type]['currentMomentumStep']); }
					options.endCallback(e);
					return false;
				}
				requestAnimFrame( animate );
				draw();
			}

			setTimeout(animate,(1000 / 60));


			function draw() {

				speedX *= (me.momentumSettings[type]['currentMomentumStep'] / 100);
				speedY *= (me.momentumSettings[type]['currentMomentumStep'] / 100);

				var now = new Date();
				var stepDuration = now.getTime() - lastStepTime.getTime();

				lastStepTime = now;


				transform.x = pos.x = (lastPos.x + (speedX * stepDuration / speedImprovement));
				transform.y = pos.y = (lastPos.y + (speedY * stepDuration / speedImprovement));

				if ( typeof options.checkBoundaries === 'function' ) {
					options.checkBoundaries();
				}
				//if(typeof console === 'object') { console.log('draw, me.momentumSettings[type][currentMomentumStep],speedX,speedY,stepDuration,pos.x,lastPos.x,pos.y,lastPos.y',me.momentumSettings[type]['currentMomentumStep'],speedX,speedY,stepDuration,pos.x,lastPos.x,pos.y,lastPos.y); }

				UTIL.transform($el,transform);

				if((Number(lastPos.x).toFixed(fixStepFloats) === Number(pos.x).toFixed(fixStepFloats)
					&& Number(lastPos.y).toFixed(fixStepFloats) === Number(pos.y).toFixed(fixStepFloats))
				) {
					//if((parseInt(lastPos.x) == parseInt(transform.x) && parseInt(lastPos.y) == parseInt(transform.y))) {
					//	if(typeof console === 'object') { console.log('draw.STOP Number(lastPos.x).toFixed(fixStepFloats),Number(pos.x).toFixed(fixStepFloats),Number(lastPos.y).toFixed(fixStepFloats),Number(pos.y).toFixed(fixStepFloats))o',Number(lastPos.x).toFixed(fixStepFloats),Number(pos.x).toFixed(fixStepFloats),Number(lastPos.y).toFixed(fixStepFloats),Number(pos.y).toFixed(fixStepFloats)); }
					me.momentumSettings[type]['currentMomentumStep'] = 1;
				}
				me.momentumSettings[type]['currentMomentumStep']--;
				lastPos = {x:pos.x,y:pos.y};
			}
		} else {
			options.endCallback(e);
		}
	};

	/** DOM HELPERS **/

	/**
	 * Wait for an element and trigger a callback function
	 * (avoid this function - this is just a quick helper)
	 */
	this.waitForElement = function(el,callback,idx) {

		var $el = $(el),
			me = this;

		if($el && $el.get(0)) {
			callback($el);
		} else if(idx<10) {
			idx++;
			setTimeout(function() {
				me.waitForElement(el,callback,idx);
			},500);
		}

	},

		/** EMBER HELPERS **/

		/**
		 * Help Function Returns a View
		 * @param n
		 * @returns {*}
		 * @private
		 * @link http://stackoverflow.com/questions/14853826/how-to-get-a-reference-on-a-view-instance-in-ember-emberjs-from-static-javascr|get reference of ember view
		 */
		this._lookupView = function(n) {

			var k;

			//if(typeof console === 'object') { console.log('_lookupView.views',Ember.View.views); }

			for(k in Ember.View.views) {
				if(!Ember.View.views.hasOwnProperty(k)) continue;
				if(Ember.View.views[k].renderedName == n) {
					return Ember.View.views[k];
				}
			}

			return false;
		};

	/**
	 * Get a controller in parent view, usefull in subviews or components
	 *
	 * UTIL._parentView_getControllerFor(this,'controllers.login-modal')
	 *
	 * @param me
	 * @param c
	 * @returns {*}
	 * @private
	 */
	this._parentView_getControllerFor = function(me,c) {

		var parentView = me.get('parentView'),
			controller;

		if(parentView) {
			var parentController = parentView.get('controller');
			if(parentController) {
				controller = parentController.get(c);
				if(!controller) {
					parentController.controllerFor(c);
				}
			}
		}

		return controller;

	};


	/** TILEZOOM HELPERS **/

	this.getUserImagePath = function(id,type) { // deprecated! --> use model.abstract-photo
		if(typeof type === 'undefined' || (!type)) {
			type = 'original';
		}
		return srvParams.wowmosaicFrontendUrlBase + '/'+type+'/'+id;
	};


	/** LAYOUT FUNCTIONS **/



	/** ORIENTATION HELPERS **/

	this.getOrientation = function () {
		var orientation = 'portrait',
			body = $('body');

		if (typeof window.orientation == 'undefined') {
			orientation = 'landscape';
			var ww = $(window).width();
			var wh = $(window).height();
			if (wh > ww) orientation = 'portrait';
		}
		if (Math.abs(window.orientation) == 90) {
			orientation = 'landscape';
		}

		orientation === 'landscape' ? body.removeClass('portrait') : body.removeClass('landscape');
		body.addClass(orientation);

		return orientation;
	};

	this.setOrientationEvent = function () {

		var supportsOrientationChange = "onorientationchange" in window;

		// patched orientation support - Andriod 1 doesn't have native onorientationchange events
		// for now, we use resize on android cause orientationchange happens b4 window width is changed to the new dimension...
		// it is maybe neccassary to specify this for newer andriod versions than the 2.2 on defy...

		if ((!supportsOrientationChange)) {
			(function () {
				var w = window.innerWidth, h = window.innerHeight;
				UTIL.RZTimeOut = false;
				UTIL.bind(window, 'resize', function (event) {
					if (UTIL.user.osPC) {
						if (UTIL.RZTimeOut !== false) clearTimeout(UTIL.RZTimeOut);
						UTIL.RZTimeOut = setTimeout(function () {
							UTIL.setOrientation();
						}, 500);
					} else {
						var portraitSwitch = (window.innerWidth < w && window.innerHeight > h) && (window.innerWidth < window.innerHeight),
							landscapeSwitch = (window.innerWidth > w && window.innerHeight < h) && (window.innerWidth > window.innerHeight);
						if (portraitSwitch || landscapeSwitch) {
							window.orientation = portraitSwitch ? 0 : 90; // what about -90?
							w = window.innerWidth;
							h = window.innerHeight;
							UTIL.setOrientation();
						}
					}
				}, false);
			})();
		} else UTIL.bind(window, 'orientationchange', function (event) {
			UTIL.setOrientation();
		}, false);

	};


	this.setBodySize = function ()
	{
		var h = jQuery(window).height();
		var w = jQuery(window).width();

		jQuery("body").css({"width": w, "height": h});
		jQuery("html").css({"width": w, "height": h});
	};

	this.resetBodySize = function ()
	{
		jQuery("body").css({"width": '100%', "height": '100%'});
		jQuery("html").css({"width": '100%', "height": '100%'});
	};

	this.setOrientation = function()
	{
		var o =  this.getOrientation(),
			me = this;
		if((!UTIL.user.osPC) && (o == this.orientation)) return false;

		function waitForSlowDevices() {
			fastdom.write(function () {
				me.resetBodySize();
				fastdom.defer(1,function () {
					fastdom.defer(1,function () {
						//DC.resize();
					});
				});
			});
			//window.scrollTo(0,1);
			//if(typeof console === 'object') { console.log('setOrientation',mosaic, typeof mosaic); }
		}

		this.orientation = o;
		defer(waitForSlowDevices,100,UTIL,[]);
	};

	/** STOARGE HANDLING **/


	this.storage = new function() {

		this.storeLocal = function(i,v) {
			localStorage[i] = v;
		};

		this.removeLocal = function(i) {
			localStorage.removeItem( i );
		};

		this.getLocal = function(i) {
			return localStorage[i];
		};

		this.storeSession = function(i,v) {
			sessionStorage[i] = v;
		};

		this.removeSession = function(i) {
			sessionStorage.removeItem( i );
		};

		this.getSession = function(i) {
			return sessionStorage[i];
		};

		this.clearAll = function() {
			localStorage.clear();
			sessionStorage.clear();
		};

		this.checkStorage = function(cache_version) {

			var USE_LOCAL_STORAGE = true,
				i;

			if(localStorage) {

				try {
					localStorage['storage-test'] = 1;
				} catch(e) {
					if(e.name === 'QUOTA_EXCEEDED_ERR') {
						try {
							localStorage.clear();
							localStorage['storage-test'] = 1;
						} catch(e) {
							USE_LOCAL_STORAGE = false;
							if(typeof console == 'object')
								console.log("Local storage write failure - " + e);
						}
					} else {
						USE_LOCAL_STORAGE = false;
						if(typeof console == 'object')
							console.log("Local storage write failure - " + e);
					}
				}

				if(USE_LOCAL_STORAGE) {

					localStorage.removeItem('storage-test');

					// check localStorage size
					var checkLSSize = function() {
						var lsBytes = 0;
						for (var i in localStorage) {
							console.log(' localStorage.i ', typeof i, i,localStorage[i].length);
							lsBytes += localStorage[i].length;
						}
						console.log(' localStorage Bytes ', lsBytes);
					};
					//checkLSSize();

					var checkCCV = function() {
						var CCV =  localStorage['cache_version'];

						if(!CCV) {
							localStorage.clear();
						}

						if(cache_version
							&& CCV
							&& CCV < cache_version) {
							localStorage.clear();
						}

						if(cache_version) try {
							localStorage['cache_version'] = cache_version;
						} catch(e) {}
					};

					checkCCV();

				}
			}

		};

	};


	/** DEVICE HANDLING **/

	this.user = new function() {

		this.osAndroid = false;
		this.osIOS = false;
		this.osPC = false;

		// devices
		this.devIPhone = false;
		this.devIPad = false;
		this.devIPod = false;

		// browsers
		this.brwWebkit = false;

		this._init = function () {

			var nav = navigator;
			var ua = nav.userAgent.toLowerCase();
			var check = function (r) {
				return r.test(ua);
			};

			this.isDevice = check(/Windows Phone|iemobile|iphone|ipod|ipad|android/gi);
			this.isRetina = 'devicePixelRatio' in window && window.devicePixelRatio > 1;

			if (check(/iphone|ipod|ipad/gi)) {
				this.osIOS = true;
			} else if (check(/android/gi)) {
				this.osAndroid = true;
			} else this.osPC = true;


			this.hasHomescreen = 'standalone' in nav && this.isDevice;
			this.isStandalone = this.hasHomescreen && nav.standalone;
		};
	};
};

UTIL = new Utils();

function initializeGmap() {

	if(!document.getElementById('gmap')) {
		return false;
	}

	var overlayLoaded = true;

	var myLatLng = new google.maps.LatLng(51.262551, 6.821873);
	var mapOptions = {
		zoom: 15,
		zoomControl: true,
		center: myLatLng,
		scrollwheel: false
	};

	var styledMapType = new google.maps.StyledMapType(
		[
			{elementType: 'geometry', stylers: [{color: '#00305d'}]}
		],
		{name: 'Styled Map'});

	var map = new google.maps.Map(document.getElementById('gmap'),
		mapOptions);

	var contentString = '<div id="googleInfoWindow">'+
		'<div id="siteNotice">'+
		'</div>'+
		'<h4 id="firstHeading">BM Partner</h4>'+
		'<div id="bodyContent">'+
		'<p><a href="https://www.google.de/maps/place/BM+Partner+Revision+GmbH+Wirtschaftsprüfungs-+gesellschaft/@51.2623733,6.8196413,17z/data=!3m1!4b1!4m5!3m4!1s0x47b8c90f0c6b6bcf:0x4450d6162fefb305!8m2!3d51.26237!4d6.82183">Kanzlerstr. 8<br />'+
		'D-40472 Düsseldorf</a></p>'+
		'<p>Tel.: +49 211 960503<br />'+
		'Fax: +49 211 9605170</p>'+	'</div>'+
		'</div>';

	map.addListener('tilesloaded',function() {
		if (!overlayLoaded) {
			// $('#gmap .gm-style > div').append('<div class="mask"></div>');
			// $('#gmap .gm-style').addClass('mask-content');
			overlayLoaded = true;
		}
	});

	var infowindow = new google.maps.InfoWindow({
		content: contentString,
		maxWidth: 350,
		zIndex: 3000000000
	});

	var marker = new google.maps.Marker({
		position: myLatLng,
		map: map,
		title: 'BM Partner'
	});

	google.maps.event.addListener(marker, 'click', function() {
		infowindow.open(map,marker);
	});


	infowindow.open(map,marker);



	// map.mapTypes.set('styled_map', styledMapType);
	// map.setMapTypeId('styled_map');

}


