/**
 * normalize all rows in team and all boxes to the same height
 */
(function($,d,w) {

	var selector = '.fitcols';
	var itemSelector = '.fitcolitem .caption';

	function init() {
		w.onresize = "setRows";
		setRows();
	};

	function setRows() {
		var fitcols = $(selector);
		if(fitcols && fitcols.length) {
			var fa = fitcols.length;
			for(var i=0; i < fa; i++) {
				var fit = $(fitcols[i]);
				var fitcolitems = fit.find(itemSelector);
				var fia = fitcolitems.length;
				var h = 0;
				var btnHeight = 40;
				for(var m=0; m < fia; m++) {
					var details_link = $(fitcolitems[m]).find('.details_link');

					if($(details_link).css('display') === 'none') {
						btnHeight = 0;
					}
					var hl = $(fitcolitems[m]).height();
					if(hl > h) {
						h = hl;
					}
				}

				fit.find(itemSelector).css('height',h + 5 + btnHeight);
			}
		}
	}

	$(d).ready(function() {
		init();
	});

})(jQuery, document, window);
