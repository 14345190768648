(function($) {

	var selector = '.latest-news .news-icon-list';
	var metaSelector = '.latest-news .news-meta';
	var url = 'https://www.deubner-online.de/mio/bin/hintergrundladen.php';
	var url_params = '?page=1&s=&m=alle&j=alle&rubriken=alle%20Zielgruppen&zielgruppen=&teasermodus=0&kdnr=51750336SR';
	var s;

	// var allowedCats = [
	// 	'alle',
	// 	'Einkommensteuer',
	// 	'übrige Steuerarten',
	// 	'Arbeitgeber und Arbeitnehmer',
	// 	'Unternehmer',
	// 	'Hausbesitzer',
	// 	'GmbH-Gesellschafter/-GF',
	// 	'Körperschaftsteuer',
	// 	'Freiberufler',
	// 	'Kapitalanleger',
	// 	'Einkommensteuer',
	// 	'Einkommensteuer',
	// 	'Einkommensteuer',
	// ];

	var init = function() {
		s = $(selector);
		if(s && s.length) {
			getAll();
		}
	};

	scrollToTop = function() {
		var h = $("#ergebnisliste").offset().top - $('#affixbottom').height();
		$('html, body').animate({
			scrollTop: h
		}, 50);
	};

	getAll = function() {
		// steuertermin1, steuertermin2, txtHint
		$.ajax(
			'https://www.deubner-online.de/mio/bin/php_inhalt.php?kdnr=51750336SR',
			{
				dataType: 'html',
				complete: function(response,status) {

					$(metaSelector).html(''+response.responseText+'');
					var listItems = $(metaSelector).find('#txtHint');
					listItems.css('display','none');

					setTimeout(function() {
						$.fn.taxNewsShowResult();
					},1000);

					if(typeof showResult === 'function') {
						showResult = $.fn.getResult;
					}
				}
			}
		);
	};

	$.fn.getResult = function(suchstr, monat, jahr, rubriken, zielgruppen, seite, teasermodus, kdnr) {
		xmlhttp = new XMLHttpRequest();
		xmlhttp.onreadystatechange = function() {
			if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
				scrollToTop();
				document.getElementById("txtHint").innerHTML = xmlhttp.responseText;
				setTimeout(function() {
					$.fn.taxNewsShowResult();
				},1000);
			}
		}
		xmlhttp.open("GET","https://www.deubner-online.de/mio/bin/hintergrundladen.php?page="+seite+"&s="+encodeURIComponent(suchstr)+"&m="+monat+"&j="+jahr+"&rubriken="+encodeURIComponent(rubriken)+"&zielgruppen="+zielgruppen+"&teasermodus="+teasermodus+"&kdnr="+kdnr,true);
		xmlhttp.send();
	};

	/*
	<li>
	<div class="icon-list__icon section--animate js--visible animated zoomIn" data-vp-add-class="js--visible animated zoomIn">
		<span class="iconlist-char" aria-hidden="true" data-av_icon="" data-av_iconfont="entypo-fontello"></span>
		<i class="icon icon-doc"></i>
		</div>

		<article class="icon-list__article" itemscope="itemscope" itemtype="https://schema.org/Article">
		<header>
		<h3 itemprop="headline" class="icon-list__article__title block-title">Abgeltungsteuer: Kein Progressionsvorbehalt für österreichische Kapitalerträge</h3>
	</header>
	<div class="icon-list__content">
		<p class="icon-list__content__paragraph">
		<meta itemprop="datePublished" content="2017-04-18T13:33:02Z">
		<span class="label label-bm-darker-beige"> 18.04.2017</span>
		<span class="label label-default">Einkommensteuer</span>
		<span class="label label-default">Kapitalanleger</span>
		</p>
		<div class="clear"></div>
		</div>

		<p itemprop="text">Seit 2009 gibt es in Deutschland die Abgeltungsteuer: Kapitalerträge werden mit 25 % versteuert und gelten damit als abgegolten. Eine andersartige Versteuerung erfolgt regelmäßig nicht, es sei denn, man stellt einen Antrag. Was das Wort Abgeltung allerdings noch bedeutet und was es für weitere Auswirkungen hat, ist nicht jedem klar - und anscheinend auch nicht immer dem Finanzamt.(...)</p>

	<footer>
	<button class="btn btn-bm-darker-beige">Weiterlesen</button>
		</footer>
		</article>
		<div class="icon-list__timeline"></div>
	</li>*/

	$.fn.taxNewsShowResult = function(response) {

		var resultSel = $('#txtHint');


		var titles = resultSel.find('.newstitel');
		var teasers = resultSel.find('.teaser');
		var contents = resultSel.find('.mio_msg_content');

		var html = '';

		for(var i=0; i<titles.length; i++) {

			var h4 = $(titles[i]);
			var teaser = $(teasers[i]);
			var content = $(contents[i]);
			var date = h4.find('.newsdatum');
			var date_str = date.html();

			var tds = content.find('table tr td:nth-child(1)');
			var cats = [];
			tds.each(function () {
				var t = $(this);
				if(t.text().trim() == 'Information für:' || t.text().trim() == 'zum Thema:') {
					cats.push(t.next('td').text());
					t.closest('table').css('display','none');
				}
			});


			date_str = date_str.replace(/[(\)]+/,'');
			date_str = date_str.replace('(','');
			date_str = date_str.replace(')','');
			h4.find('.newsdatum').remove();
			teaser.find('.toggle').remove();
			var h4_str = h4.html();

			html += '<li class="icon-list__listitem">';
			// html += '<div class="icon-list__icon section--animate js--visible animated zoomIn" data-vp-add-class="js--visible animated zoomIn">' +
			// 	'<span class="iconlist-char" aria-hidden="true" data-av_icon="" data-av_iconfont="entypo-fontello"></span>' +
			// 	'<i class="icon icon-doc"></i>' +
			// 	'</div>';

			html += '<div class="icon-list__icon section--animate__icons js--hidden" data-vp-add-class="js--visible animated zoomIn">' +
				'<span class="iconlist-char" aria-hidden="true" data-av_icon="" data-av_iconfont="entypo-fontello"></span>' +
				'<i class="icon icon-doc"></i>' +
				'</div>';

			html += '<article class="icon-list__article">';
			html += '<header>';
			html += '<h3 class="icon-list__article__title block-title">' +h4_str+'</h3>';
			html += '</header>';
			html += '';
			html += '<div class="icon-list__content">';
			html += '';
			html += '';
			html += '';
			html += '<p class="icon-list__content__paragraph">';
			html += '<span class="label label-bm-darker-beige">'+date_str+'</span>';
			html += '';
			for(var s=0;s<cats.length;s++) {

				html += '<span class="label label-default">'+cats[s]+'</span>';

			}
			html += '';
			html += '</p>';
			html += '<div class="clear"></div>';
			html += '</div>';
			html += '';
			html += '';
			html += '';
			html += '';
			html += '';
			html += '<div class="news-teaser">' + teaser.html();
			html += '<footer><button class="btn btn-bm-darker-beige">Weiterlesen</button></footer>';
			html +=	'</div>';

			html += '<div class="news-content" style="display: none;">' + content.html()+'</div>';
			html += '';

			html += '';

			html += '';
			html += '</article>';
			html += '<div class="icon-list__timeline"></div>';
			html += '</li>';
		}


		// paginierung
		$(selector).parent().find('.paging').remove();
		var paginierung = resultSel.find('.paginierung');
		var phtml = '';
		if(paginierung && paginierung.length) {
			phtml += '<div class="paging">';
			phtml += '';
			phtml += '' + paginierung.get(0).innerHTML;
			phtml += '';
			phtml += '</div>';
		}

		$(selector)[0].innerHTML = html;//html(html);
		// $(selector).html(html);
		$(selector).parent().append(phtml);
		addActions();
		animateIcons();
	};


	animateIcons = function() {

		$('.section--animate__icons').viewportChecker({
			classToAdd: 'js--visible animated fadeInLeftBig', // Class to add to the elements when they are visible,
			classToAddForFullView: 'full-visible', // Class to add when an item is completely visible in the viewport
			classToRemove: 'js--hidden', // Class to remove before adding 'classToAdd' to the elements
			// removeClassAfterAnimation: false, // Remove added classes after animation has finished
			offset: '10%', // The offset of the elements (let them appear earlier or later). This can also be percentage based by adding a '%' at the end
			// invertBottomOffset: true, // Add the offset as a negative number to the element's bottom
			repeat: false // Add the possibility to remove the class if the elements are not visible
			// callbackFunction: function(elem, action){}, // Callback to do after a class was added to an element. Action will return "add" or "remove", depending if the class was added or removed
			// scrollHorizontal: false // Set to true if your website scrolls horizontal instead of vertical.
		});
	};

	addActions = function() {
		$(selector +' .btn').on('click',function() {
			var article = $(this).closest('article');
			article.find('.news-teaser').css('display','none');
			article.find('.news-content').css('display','block');
		});

	};

	$(document).ready(function() {
		init();
	});

})(jQuery);
