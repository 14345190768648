/**
 * Simple Google Maps Script
 *
 * Dependencies
 * viewportChecker
 *
 */
(function($) {

	var init = function() {

		if($('#interim-modal').get(0)) {
			checkModal();
		}
	};


	checkModal = function() {

		var ms = UTIL.storage.getLocal('interim-seen');

		if(!ms) {
			loadModal();
			UTIL.storage.storeLocal('interim-seen',1);
		}

	};

	loadModal = function() {

		$('#interim-modal').modal('show');


	};

	$(document).ready(function() {
		init();
	});

})(jQuery);
